<template>

  <div>

    <div class="generateIzjavaProstovoljcaOuterContainer">

      <div id="my-node3" class="" v-if="volunteersData">

        <div class="generateIzjavaProstovoljcaContainer">
          <img :src="require('@/assets/images/content/business_letters/businessLetterBg.png')" class="bgImg">
          <h3>IZJAVA PROSTOVOLJCA</h3>


          <p>Spodaj podpisani prostovoljec <u>{{ volunteersData.first_name + ' ' + volunteersData.last_name }}</u>, rojen <u>{{ moment(volunteersData.birthday).format('DD. MM. YYYY') }}</u>,
            stanujoč na naslovu <u>{{volunteersData.addresses.quick_search}}</u>, izjavljam:
          </p>

          <p>
          <ul>
            <li>da imam veljavno vozniško dovoljenje za B kategorijo,
            </li>
            <br/>
            <li>da dovoljujem obdelavo osebnih podatkov za namene opravljanja prostovoljskega dela pri Zavodu
              Sopotniki, med drugim tudi fotokopiranje vozniškega dovoljenja in hranjenje fotokopije,
            </li>
            <br/>
            <li>da ne obstajajo zdravstvene ali druge okoliščine, ki bi mi onemogočale ali bistveno
              oteževale izpolnjevanje obveznosti ali bi lahko ogrozile moje zdravje ali življenje in zdravje
              oseb, s katerim med opravljanjem prostovoljskega dela prihajam v stik,
            </li>
            <br/>
            <li>da mi ni bil izrečen varnostni ukrep obveznega psihiatričnega zdravljenja ali obveznega
              zdravljenja odvisnosti ali prepovedi opravljanja poklica, dela ali dolžnosti, ki je v zvezi s
              področjem opravljanja prostovoljskega dela. Zoper mene ni bil uveden kazenski postopek in/ali
              nisem bil pravnomočno obsojen za kaznivo dejanje zoper življenje in telo, spolno
              nedotakljivost ali za protipravno pridobitev premoženjske koristi,
            </li>
            <br/>
            <li>da soglašam (ustrezno označi):<br/><br/>

              <div>
                <div style="width: 14px; height: 14px; border: 1px solid black; float: left; margin-right: 10px;"></div>
                s fotografiranjem in snemanjem za objavo fotografij in posnetkov na spletni strani in
                družabnih omrežjih Zavoda Sopotniki (facebook ipd.), v gradivih Zavoda Sopotniki na
                dogodkih oz. izobraževanjih ter objavo v medijih z namenom obveščanja širše javnosti o
                dejavnostih Zavoda Sopotniki.
              </div>
              <br/>

              <div>
                <div style="width: 14px; height: 14px; border: 1px solid black; float: left; margin-right: 10px;"></div>
                da Zavod Sopotniki uporablja in hrani moje osebne podatke ter jih obdeluje za namen
                izvajanja svoje dejavnosti in obveščanja o aktivnostih ter dogodkih, ki niso povezane z
                opravljanjem prostovoljskega dela po tem dogovoru.
              </div>
              <br/>

            </li>
          </ul>
          </p>


          <table style="width: 100%; border: 0px;">
            <tr>
              <td>
                <p>
                  <br/><br/>
                  Kraj in datum: <br/><br/>

                  {{volunteersData.units.locationForContracts}}, ___________________________

                </p>
              </td>
              <td>

                <p>
                  <br/><br/>
                  Podpis: <br/><br/>

                  ___________________________
                </p>
              </td>
            </tr>
          </table>

          <p>

            <br/><br/><br/>
            Skladno z zakonom, ki ureja varstvo osebnih podatkov, lahko zahtevate dostop, dopolnitev, popravek,
            omejitev obdelave, prenos ali izbris osebnih podatkov ali vložite ugovor zoper obdelavo osebnih
            podatkov, ki se obdelujejo v zvezi z vami, s pisno zahtevo, poslano na e- naslov:
            info@sopotniki.org.
          </p>


        </div>

      </div>

    </div>

  </div>

</template>

<script>
import Vue from "vue";
import VueQrcode from "vue-qrcode";

export default {
  name: 'IzjavaProstvoljca',

  components: {
    VueQrcode
  },
  data() {
    return {
      volunteersData: null

    }
  },

  computed: {

  },

  mounted() {
    const _this = this;

    _this.$vs.loading();

    console.log(Vue.prototype.$http.defaults.headers);
    console.log("this.$route.query", this.$route.query);

    if (_this.$route.params.volunteerId) {
      if (this.$route.query.jwt) {
        Vue.prototype.$http.interceptors.request.use(
          config => {
            const token = this.$route.query.jwt;
            if (token) {
              if (config.url.includes('https://api.sopotniki.org/')) {
                config.headers.common["Authorization"] = 'Bearer ' + token;
              } else {
                config.headers.common["Authorization"] = token;
              }
            }
            return config;
          },
          error => {
            return Promise.reject(error);
          }
        );

      }


      _this.getVolunteerData();


    } else {
      _this.$vs.loading.close();
    }
  },


  methods: {
    async getVolunteerData() {
      const _this = this;
      _this.$vs.loading();

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/' + _this.$route.params.volunteerId)
        .then((res) => {
          _this.volunteersData = res.data.data;
          _this.$vs.loading.close();
          console.log("volunteersData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
    pad(num, size) {
      let s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
  }
}
</script>

<style lang="scss">
html, body {
  width: 210mm;
  height: 297mm;
}
@page {
  size: A4;
  margin: 0;
  -webkit-print-color-adjust: exact !important;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}

.generateIzjavaProstovoljcaOuterContainer {
  font-family: 'Open Sans' !important;
  color: black !important;

  .generateIzjavaProstovoljcaContainer {
    position: relative;
    width: 210mm;
    height: 296mm;
    background-size: contain !important;
    -webkit-print-color-adjust: exact !important;
    padding: 18% 20% 15% 7% !important;
    display: block !important;
    color: black !important;

    overflow: hidden !important;

    font-size: 10px;
    text-align: justify;

    page-break-inside: avoid;

    .bgImg {
      width: 210mm;
      height: 296mm;
      position: absolute;
      z-index: -1;
      left: 0px;
      top: 0px;
    }

    u {
      font-weight: bold;
    }

    &:first-child {
      padding: 40% 28% 15% 7% !important;
    }

    h3 {
      text-transform: none;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    h4 {
      margin-top: 20px;
      font-size: 11px;
      text-transform: none;
      text-align: center;
    }

  }

}
</style>
